import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutSection.css';
import signature from '../../signature.png';

const AboutSection = () => {
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    const handleScroll = () => {
      const aboutSection = document.querySelector('.about-section');
      const aboutDetails = document.querySelector('.about-details');
      if (aboutSection && aboutDetails) {
        const sectionHeight = aboutSection.clientHeight;
        const scrollPosition = window.scrollY + window.innerHeight;
        const aboutPosition = aboutSection.offsetTop + sectionHeight;

        if (scrollPosition > aboutPosition) {
          setShowDetails(true);
        } else {
          setShowDetails(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleClick = () => {
    navigate('/catering-eventos/catering');
  };

  return (
    <div className="about-section">
      <div className="content-container">
        <div className="about-header">
          Ode à alegria <br></br> de partilhar
        </div>
        <div className="abouts">
          <div className="description1">O luˈkaɫ é uma agência de catering, dedicada à organização e produção de eventos sociais e corporativos. </div>
          <div className="description2">Garantimos um serviço de catering especializado,
            com um natural sentido de hospitalidade,
            disponibilizando <br></br> igualmente serviços completos,
            para receber os seus convidados
            e proporcionar-lhes experiências gastronómicas marcantes.</div>
          <div className="description3">Entendemos a importância de criar uma experiência
            única, adaptada às exigências específicas de cada evento. <br></br>
            Disponibilizamos serviços de design, gestão e acompanhamento de eventos particulares e corporativos.</div>
          <div class="signature-image-container">
            <img src={signature} alt="Signature" class="signature" />
          </div>
        </div>
        <div className={`about-details ${showDetails ? 'visible' : ''}`}>
          <button className="more-info" onClick={handleClick}>
            Pedido de Proposta
          </button>

        </div>
      </div>
    </div>
  );
};

export default AboutSection;