
import React, { useEffect, useState } from 'react';
import menuImage from './menuImage.png';
import './MenuSection.css'; // Make sure to import the CSS file
import { Link } from 'react-router-dom';

const MenuSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const images = [
    { src: menuImage }
  ];

  return (
    <>
      <div className="section menu-section">
        <div className="restaurant-content">
          <div className="restaurant-title">Crafted for enjoyment, 
            <br></br> without snobbery</div>
          <p className="restaurant-description">
            Inspirado no melhor das nossas regiões, reunimos os melhores curadores, com produtos locais e que respeitam a sazonalidade.
            A proposta é um salmo aos sabores tradicionais e à inovação dos curadores que nos merecem total confiança e apoio
          </p>
          <div className="restaurant-links">
            <div className="restaurant-link">
              <Link>MENUS</Link>
              <div className="dropdown">
              <a href="./menuessential2024.pdf">
                Essential
              </a>
                <a href="./menuclassic2024.pdf">
                  Classic
                </a>
                <a href="./menuprestige2024.pdf">
                   Prestige
                </a>
              </div>

            </div>
            <div className="restaurant-link">
              <a href="./menubar2024.pdf">
              BAR
              </a>
              
              <div className="dropdown">
              
              </div>
            </div>
            <div className="restaurant-link-proposal">
              <Link to="/catering-eventos/catering">PROPOSTA DE CATERING</Link>
            </div>
          </div>

        </div>
        <div className="restaurant-image-container">
          <img
            src={menuImage}
            alt="Restaurant Dish"
            className="restaurant-image"
          />
        </div>
      </div>
    </>
  )
};

export default MenuSection;
