import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './PhotoGallery.css';
import image1 from './1.webp';
import image2 from './2.webp';
import image3 from './3.webp';
import image4 from './4.webp';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ThreeDots } from 'react-loader-spinner'; // Import the specific spinner

const images = [
  { src: image1 }
];

const PhotoGallery = () => {
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1, // Display only one slide by default
    slidesToScroll: 1,
    draggable: true,
    variableWidth: true,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 765,
        settings: {
          slidesToShow: 1, // Only one slide on small screens
          centerMode: false, // Center mode off for small screens
          centerPadding: '0px' // No padding on small screens
        }
      }
    ]
  };

  useEffect(() => {
    // Simulate image loading
    const imagePromises = images.map((image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image.src;
        img.onload = resolve;
      });
    });

    Promise.all(imagePromises).then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
      ) : (
        <>
          <div className="gallery-container">
            <div className="swipegallery">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="swiper-slide" style={{ width: `100%` }}>
                    <img className="pog" src={image.src} alt="Catering de Eventos" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="gallery-container-phone">
            <div className="swipegallery">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index} className="swiper-slide" style={{width:'450px'}}>
                    <img className="pog" src={image.src} alt="Catering de Eventos" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PhotoGallery;
