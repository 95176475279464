import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './AgendaSection.css';
import { handleNavigationClick } from './functions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import mercadoSezim from './MercadoSezim.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';

const AgendaSection = () => {
  const [showDetails, setShowDetails] = useState(false);

  const events = [
    {
      img: mercadoSezim,
      location: 'Rua de Sezim, Guimarães',
      time: ' Das 11h às 19h',
      date: '7 & 8 de Dezembro',
      title: 'Mercado em Sezim',
      alt: 'Mercado em Sezim',
      url: 'https://www.instagram.com/mercadoemsezim/'
    }
  ];

  useEffect(() => {
    handleNavigationClick(0);

    const handleScroll = () => {
      const contactSection = document.querySelector('.contact-section');
      const contactDetails = document.querySelector('.contact-details');
      if (contactSection && contactDetails) {
        const sectionHeight = contactSection.clientHeight;
        const scrollPosition = window.scrollY + window.innerHeight;
        const contactPosition = contactSection.offsetTop + sectionHeight;

        if (scrollPosition > contactPosition) {
          setShowDetails(true);
        } else {
          setShowDetails(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Slider settings
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="agenda-section">
      <div className="content-container">
        <header className="agenda-header">
          Agenda
        </header>
      </div>
      <div className="whatsOn">
        <div class="event-wrap">
          <Slider {...settings}>
            {events.map((event, index) => (

              <div key={index} className="event-card">
                <a target='_blank' href="https://www.instagram.com/mercadoemsezim/" class="link-to-event">
                  <img src={event.img} alt={event.alt} className="event-image" />
                </a>
                <div className="event-info">
                  <div class="event-info-container">
                    <p>
                      <div class="info-event">
                        <div class="info-location">
                          <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                          {event.location}
                        </div>
                        <div class="info-time">
                          <FontAwesomeIcon icon={faClock} className="icon" />
                          {event.time}
                        </div>
                      </div>
                    </p>
                    <div class="info-date">
                      <p>{event.date}</p>
                    </div>
                    <h1>{event.title}</h1>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AgendaSection;
