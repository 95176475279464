// src/LocationsSection.js
import React from 'react';
import './FooterSection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faSpotify } from '@fortawesome/free-brands-svg-icons';


const FooterSection = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
          <div className="social-icons-container">
          <ul className="social-icons">
            <li><a href="https://www.instagram.com/lu__kat/"><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li><a href="http://www.tiktok.com/@lu__kat"><FontAwesomeIcon icon={faTiktok} /></a></li>
            <li><a href="https://open.spotify.com/playlist/2AoawW3WKF6pL64QT0jEEr?si=NKmgKSS1SICfuo93RC1CWg&pi=e-jJHk_DI5RSiR"><FontAwesomeIcon icon={faSpotify} /></a></li>
          </ul>
          </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
          <a href="./politicadeprivacidade.pdf">Política de Privacidade</a> | <a href="./politicadecookies.pdf">Política de Cookies</a>
        </div>
        <div className="footer-copyright">
          <p>Copyright 2024 © lu'kat. Todos os direitos reservados. Criado por ZAZU.</p>
        </div>
      </div>
    </footer>
  );
};



export default FooterSection;

