import React, { useEffect, useState, useRef } from 'react';
import './CateringSection.css';
import { handleNavigationClick } from '../functions.js';
import ContactForm from './ContactForm.js';
import VideoSection from './VideoSection.js';

const CateringSection = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    handleNavigationClick(0);

    // Trigger the fade-in effect after the component mounts
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Slight delay to ensure the component has mounted

    // Intersection Observer for the video section
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVideoVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <div className="catering-section">
      <div className="catering-container">
        <p className={`catering-title ${isVisible ? 'fade-in' : ''}`} id="catering-title">Pedido de proposta</p>
        <header className={`catering-header ${isVisible ? 'fade-in' : ''}`} id="catering-header">
          <div className="catering">
            <ul className="catering-list">
              <li className={`first ${isVisible ? 'fade-in' : ''}`} id="first">From</li>
              <li className={`second ${isVisible ? 'fade-in' : ''}`} id="first">boardrooms</li>
            </ul>
            <ul className="catering-list">
              <li className={`third ${isVisible ? 'fade-in' : ''}`} id="first">To</li>
              <li className={`fourth ${isVisible ? 'fade-in' : ''}`} id="first">ballrooms</li>
            </ul>
          </div>
        </header>
        <div className={`description-catering ${isVisible ? 'fade-in' : ''}`} id="description-catering">
          Faça aqui o pedido de proposta para o seu evento. Preencha o formulário e a nossa equipa voltará ao seu contacto.
        </div>
      </div>
    </div>
  );
};

export default CateringSection;
