import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navigation.css';
import smoothscroll from 'smoothscroll-polyfill'; // Import the smoothscroll polyfill
import {handleNavigationClick} from '../../functions.js';
import PhotoGallery from './PhotoGallery';

smoothscroll.polyfill();

const Navigation = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  
  React.useEffect(() => {
    handleNavigationClick(0); // Pass the desired scroll amount in pixels
    // Trigger the fade-in effect after the component mounts
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Slight delay to ensure the component has mounted
  }, []);
  



  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  useEffect(() => {
    const body = document.querySelector('body');
    if (menuOpen) {
      body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      body.style.overflow = 'auto'; // Enable scrolling
    }
    return () => {
      body.style.overflow = 'auto'; // Ensure scrolling is re-enabled when component unmounts
    };
  }, [menuOpen]);

  return (
    <div className={`navigation-section ${isVisible ? 'fade-in' : ''}`}>
      <div className={`gallery-section ${isVisible ? 'fade-in' : ''}`} >
       <PhotoGallery />
      </div>
    </div>
  );
};

export default Navigation;