import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import lukatLogo from './lukat3.svg';
import smoothscroll from 'smoothscroll-polyfill'; // Import the smoothscroll polyfill
import { handleNavigationClick } from './functions.js';

smoothscroll.polyfill();

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [shrink, setShrink] = useState(false);
  const location = useLocation();


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };



  useEffect(() => {
    const body = document.querySelector('body');
    if (menuOpen) {
      body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      body.style.overflow = 'auto'; // Enable scrolling
    }
    return () => {
      body.style.overflow = 'auto'; // Ensure scrolling is re-enabled when component unmounts
    };
  }, [menuOpen]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={`navbar-section ${shrink ? 'shrink' : ''}`}>
      <div className="background-container">
        <div className="navbar-container">
          <header className="navbar-header">
            <div className="logo">
              <a href="/catering-eventos">
                <img className="imageLogo" src={lukatLogo} alt="lukat" />
              </a>
            </div>
            <button className={`menu-button ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </button>
          </header>
          <div className={`navbar-content ${menuOpen ? 'open' : ''}`}>
            <div className="pages-list">
              <ul className="list">
                <li>
                  <Link
                    to="/catering-eventos/sobre"
                    className={`no-style-link ${location.pathname === '/catering-eventos/sobre' ? 'active-link' : ''}`}
                    onClick={() => { toggleMenu(); handleNavigationClick('about-section'); }}
                  >
                    SOBRE
                  </Link>
                </li>
                <li>
                  <Link
                    to="/catering-eventos/menu"
                    className={`no-style-link ${location.pathname === '/catering-eventos/menu' ? 'active-link' : ''}`}
                    onClick={() => { toggleMenu(); handleNavigationClick('menu-container'); }}
                  >
                    MENU
                  </Link>
                </li>
                <li>
                  <Link
                    to="/catering-eventos/catering"
                    className={`no-style-link ${location.pathname === '/catering-eventos/catering' ? 'active-link' : ''}`}
                    onClick={() => { toggleMenu(); handleNavigationClick('catering-section'); }}
                  >
                    PROPOSTA CATERING
                  </Link>
                </li>
                <li>
                  <Link
                    to="/catering-eventos/agenda"
                    className={`no-style-link ${location.pathname === '/catering-eventos/agenda' ? 'active-link' : ''}`}
                    onClick={() => { toggleMenu(); }}
                  >
                    AGENDA
                  </Link>
                </li>
                <li>
                  <Link
                    to="/catering-eventos/contactos"
                    className={`no-style-link ${location.pathname === '/catering-eventos/contactos' ? 'active-link' : ''}`}
                    onClick={() => { toggleMenu(); handleNavigationClick('contact-section'); }}
                  >
                    CONTACTOS
                  </Link>
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;