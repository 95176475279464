import logo from './logo.svg';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './Navbar';
import Navigation from './MainPage/Main/Navigation';
import ContactSection from './ContactSection';
import CateringSection from './Catering/CateringSection';
import MenuSection from './MenuSection';
import FooterSection from './FooterSection';
import ConceptSection from './MainPage/Concept/ConceptSection.js';
import AboutSection from './MainPage/About/AboutSection';
import AgendaSection from './AgendaSection'
import ContactForm from './Catering/ContactForm.js'
import './App.css';
import CookieConsent from 'react-cookie-consent'

function App() {
  useEffect(() => {
    // Add the fade-in class to the body once the component is mounted
    document.body.classList.add('fade-in');
  }, []);
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Navigate to="/catering-eventos" />} />
          <Route path="/catering-eventos" element={<Navigation />} />
          <Route
            path="/catering-eventos/sobre"
            element={
              <React.Fragment>
                <AboutSection />
              </React.Fragment>
            }
          />
          <Route path="/catering-eventos/menu" element={<MenuSection />} />
          <Route
            path="/catering-eventos/catering"
            element={
              <React.Fragment>
                <CateringSection />
                <ContactForm />
              </React.Fragment>
            }
          />
          <Route path="/catering-eventos/contactos" element={<ContactSection />} />
          <Route path="/catering-eventos/agenda" element={<AgendaSection />} />
        </Routes>
        <CookieConsent 
        location='bottom'
        style={{display:'flex',
           alignItems:'center',
           background: '#F9f7DC',
           color: '#000'}}
        buttonStyle={{background:'#0b0f7b',
          color:'#F9f7DC',
          fontSize:'14px',
          borderRadius:'4px'
        }}
        debug={false}
        buttonText="Compreendido"
        expires={30}
        >
          Este <em>Site</em> utiliza cookies para permitir uma melhor experiência 
          por parte do utilizador.
          Ao navegar no <em>Site</em> estará a consentir com 
          a sua utilização.
          <a href='/'>Saber mais.</a>
          
        </CookieConsent>
        <FooterSection />
      </div>
    </Router>
  );
}

export default App;
