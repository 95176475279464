import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactSection.css';

const ContactSection = () => {
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();

  

  useEffect(() => {
    const handleScroll = () => {
      const contactSection = document.querySelector('.contact-section');
      const contactDetails = document.querySelector('.contact-details');
      if (contactSection && contactDetails) {
        const sectionHeight = contactSection.clientHeight;
        const scrollPosition = window.scrollY + window.innerHeight;
        const contactPosition = contactSection.offsetTop + sectionHeight;

        if (scrollPosition > contactPosition) {
          setShowDetails(true);
        } else {
          setShowDetails(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const handleClick = () => {
    navigate('/catering-eventos/catering');
  };

  return (
    <div className="contact-section">
      <div className="content-container">
        <header className="contact-header">
          Contactos
        </header>
        <div className="description">
          Aqui, encontrará todos os contactos de que precisa para falar connosco.<br />
          <div className="description-1">
            Para propostas de catering, colaborações ou outros assuntos,<br />
            por favor contacte-nos todos os dias entre as 10 e as 22.
          </div>
          <div className={`contact-details ${showDetails ? 'visible' : ''}`}>
            <div className="contacts">
              <strong>T</strong> <a className="numberRef" href="tel:+351917065744">+351 917 065 744</a>
              <div className="telephone">
                Chamada para rede móvel nacional.
              </div>
              <div className="email">
                <strong>E</strong> <a className="emailRef" href="mailto:info@lukat.pt">info@lukat.pt</a>
              </div>
            </div>
            <button className="more-info" onClick={handleClick}>
              Mais Informações
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
