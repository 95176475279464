import React, { useState } from 'react';
import './ContactForm.css';
import emailjs from 'emailjs-com';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { waitFor } from '@testing-library/react';

const ContactForm = () => {
    const [step, setStep] = useState(1);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [formData, setFormData] = useState({
        titulo: '',
        nome: '',
        telefone: '',
        telefoneAlternativo: '',
        email: '',
        pais: '',
        eventoNome: '',
        data: '',
        localizacao: '',
        descricao: ''
    });
    const [errors, setErrors] = useState({});

    const handleNext = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setStep(step + 1);
        }
    };

    const handlePrev = (e) => {
        e.preventDefault();
        setStep(step - 1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        setIsPrivacyChecked(e.target.checked);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await emailjs.send('service_rtmql6f', 'template_ofrlh9i', formData, 'bJTfa7qNuWTWZBeIx');
                alert('Form submitted successfully.');
                setStep(4);
                sendConfirmationEmail(formData.email, formData.nome);
            } catch (error) {
                console.error('Error submitting form:', error);
                alert('Error submitting form. Please try again later.');
            }
        }
    };

    const sendConfirmationEmail = async (userEmail, nome ) => {
        try {
            await emailjs.send('service_rtmql6f', 'template_br9x0vp', { to_email: userEmail, userName : nome }, 'bJTfa7qNuWTWZBeIx');
            //resetForm();
        } catch (error) {
            console.error('Error sending confirmation email:', error);
            //resetForm();
        }
    };

    const resetForm = () => {
        setTimeout(() => {
            setFormData({
                titulo: 'Sr.',
                nome: '',
                telefonePrefix: '+351',
                telefone: '',
                telefoneAlternativo: '',
                email: '',
                pais: '',
                eventoNome: '',
                data: null,
                localizacao: '',
                descricao: ''
            });
            setStep(1);
            setIsPrivacyChecked(false);
            setErrors({});
        }, 5000);
    };



    const validateForm = () => {
        let formErrors = {};
        if (step === 1) {
            if (!formData.titulo) formErrors.titulo = true;
            if (!formData.nome) formErrors.nome = true;
            if (!formData.telefone) formErrors.telefone = true;
            if (!formData.email) formErrors.email = true;
        } else if (step === 2) {
            if (!formData.eventoNome) formErrors.eventoNome = true;
            if (!formData.data) formErrors.data = true;
            if (!formData.localizacao) formErrors.localizacao = true;
            if (!formData.descricao) formErrors.descricao = true;
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    return (
        <div className="form-container">
            <div className="step-indicator">
                <div className={`step ${step === 1 ? 'active' : ''}`}>
                    <div className="circle">1</div>
                    <div className="label">INFORMAÇÃO DO CONTACTO</div>
                </div>
                <div className={`step ${step === 2 ? 'active' : ''}`}>
                    <div className="circle">2</div>
                    <div className="label">DETALHE DO EVENTO</div>
                </div>
                <div className={`step ${step === 3 ? 'active' : ''}`}>
                    <div className="circle">3</div>
                    <div className="label">SUBMETER</div>
                </div>
            </div>

            <form className="contact-form" onSubmit={handleNext}>
                {step === 1 && (
                    <>
                        <div className="step-title">CONTACTO</div>
                        <div className="form-group">
                            <label htmlFor="titulo">Titulo</label>
                            <input
                                type="text"
                                id="titulo"
                                name="titulo"
                                value={formData.titulo}
                                onChange={handleChange}
                            />
                            {errors.titulo && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="nome">Nome completo</label>
                            <input
                                type="text"
                                id="nome"
                                name="nome"
                                value={formData.nome}
                                onChange={handleChange}
                            />
                            {errors.nome && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefone">Número de telefone</label>
                            <input
                                type="text"
                                id="telefone"
                                name="telefone"
                                value={formData.telefone}
                                onChange={handleChange}
                            />
                            {errors.telefone && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="telefone-alternativo">Número de telefone alternativo</label>
                            <input
                                type="text"
                                id="telefone-alternativo"
                                name="telefoneAlternativo"
                                value={formData.telefoneAlternativo}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                            {errors.email && <span className="error">X</span>}
                        </div>
                        <button type="submit" className="next-button">SEGUINTE</button>
                    </>
                )}

                {step === 2 && (
                    <>
                        <div className="step-title">EVENTO</div>
                        <div className="form-group">
                            <label htmlFor="evento-nome">Nome do Evento</label>
                            <input
                                type="text"
                                id="evento-nome"
                                name="eventoNome"
                                value={formData.eventoNome}
                                onChange={handleChange}
                            />
                            {errors.eventoNome && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="data">Data do Evento</label>
                            <input
                                type="date"
                                id="data"
                                name="data"
                                value={formData.data}
                                onChange={handleChange}
                            />
                            {errors.data && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="localizacao">Localização</label>
                            <input
                                type="text"
                                id="localizacao"
                                name="localizacao"
                                value={formData.localizacao}
                                onChange={handleChange}
                            />
                            {errors.localizacao && <span className="error">X</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="descricao">Descrição</label>
                            <textarea
                                id="descricao"
                                name="descricao"
                                value={formData.descricao}
                                onChange={handleChange}
                            />
                            {errors.descricao && <span className="error">X</span>}
                        </div>
                        <div className="button-container">
                            <button onClick={handlePrev} className="prev-button">VOLTAR</button>
                            <button type="submit" className="next-button">SEGUINTE</button>
                        </div>
                    </>
                )}

                {step === 3 && (
                    <>
                        <div className="step-title">SUBMETER</div>
                        <div className="form-submit">
                            <p className="submit-phrase">Depois de submetido o formulário,<br/> o seu pedido será analisado pela nossa equipa<br/> que entrarará em contacto assim que possível.</p>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                id="privacy"
                                checked={isPrivacyChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label className="privacy-phrase" htmlFor="privacy">
                                Eu li a <a className="politica" href="/politica-de-privacidade" target="_blank">política de privacidade</a>e dou o meu consentimento para que
                                José Esteves Pinto, Unipessoal trate os meus dados para propósitos de marketing.
                            </label>
                        </div>
                        <div className="button-container">
                            <button onClick={handlePrev} className="prev-button">VOLTAR</button>
                            <button onClick={handleSubmit} className="submit-button" disabled={!isPrivacyChecked}>SUBMETER</button>
                        </div>
                    </>
                )}
                {step === 4 && (
                    <>
                        <div className="form-submit">
                            <p className="after-submit-phrase">Obrigado pelo seu interesse em reservar o seu evento connosco. A nossa equipa irá dar seguimento e voltar ao seu contacto em breve.</p>
                        </div>
                    </>
                )}
            </form>
        </div>
    );
};

export default ContactForm;
